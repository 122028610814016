import React from "react";
import { Container, Grid } from "semantic-ui-react";
import styled from "styled-components";
import advisoryCouncils, { IAdvisoryCouncil } from "../data/advisoryCouncils";
import CAALogo from "../../static/images/caafoundation.png";


export const CouncilContainer = styled.div`
  color: #fff;
  background-color: #FAB60B;
  padding: 3em 0;
  margin: 5em 0;
  .advisoryCouncilItem{
    margin-bottom: 3em;
    .councilName {
      font-size: 1.5em;
    }
    .executive{
      margin-bottom: 1em;
      .name {
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 5px;
      }
      .title {
        font-size: 1.1em;
        line-height: 1.5em;
      }
    }
    .caaLogo {
      width: 250px;
    }
    @media(min-width: 768px){
      .councilName {
        min-height: 65px;
        font-size: 1.8em;
      }
    }
  }
`;

interface IAdvisoryCouncilItemProps {
  advisoryCouncil: IAdvisoryCouncil
}
const AdvisoryCouncilItem = ({ advisoryCouncil }: IAdvisoryCouncilItemProps)=>{
  return(
    <div className="advisoryCouncilItem">
      <h2 className="councilName">{advisoryCouncil.name}</h2>
      {advisoryCouncil.executives.map((exec, idx)=>(
        <div key={idx} className="executive">
          <div className="name"> {exec.name} </div>
          <div className="title"> {exec.title} </div>
        </div>
      ))}
    </div>
  )
}


const AdvisoryCouncilList = ()=>{
  return(
    <CouncilContainer>
      <Container>
        <Grid>
          <Grid.Row>
            {advisoryCouncils.map((advisoryCouncil, idx) =>(
              <Grid.Column key={idx} mobile={16} tablet={8} computer={4}>
                <AdvisoryCouncilItem advisoryCouncil={advisoryCouncil} />
              </Grid.Column>
            ))}
            <Grid.Column width="16">
              <div className="advisoryCouncilItem">
                <h2 className="councilName">Full Story Initiative <br/> Founding Partner</h2>
                <img className="caaLogo" src={CAALogo} alt="CAA Foundation Logo"/>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
          <p><sup>*</sup>The United Nations is an international organization</p>
        </div>
      </Container>
    </CouncilContainer>
  )
}


export default AdvisoryCouncilList;