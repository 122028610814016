
import React from "react";
import { Helmet } from "react-helmet";
import { Container, Header } from "semantic-ui-react";
import styled from "styled-components";
import Layout from "../components/Layout";
import AdvisoryCouncilList from "../components/AdvisoryCouncilList";
import { MainHeader } from "../helpers/styledComponents";

const PageContainer = styled(Container)`
  
`;

export default () => {

  return (
    <>
      <Helmet>
        <title>Advisory Council | Full Story Initiative </title>
        <meta name="title" content="Advisory Council | Full Story Initiative" />
        <meta name="og:title" content="Advisory Council | Full Story Initiative" />
        <meta name="twitter:title" content="Advisory Council | Full Story Initiative" />
        <meta name="og:site_name" content="Advisory Council | Full Story Initiative" />
      </Helmet> 
      <Layout>
        <PageContainer>

          <MainHeader>
            <Header className="h1 compact">
              NON-PROFIT <br />
              ADVISORY COUNCIL
            </Header>
            <Header className="h3 dark extraSpace">
              The Non-Profit Advisory Council is composed of non-profit organizations and culture change leaders who represent a broad range of social issues and historically underrepresented communities.
            </Header>
            <Header className="h4" style={{marginTop: "5em"}}>
              We work in close partnership with the council on industry facing resources, <br />
              relevant events and workshops, and facilitate creator collaborations on a project by project basis.
            </Header>
          </MainHeader>

        </PageContainer>

        <AdvisoryCouncilList />
      </Layout>
    </>
  )
};
