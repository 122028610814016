
export interface IAdvisoryCouncil {
  name: string;
  executives: Array<{
    name: string;
    title: string;
  }>
}

const advisoryCouncils:Array<IAdvisoryCouncil> = [
{
  "name": "ACLU: American Civil Liberties Union",
  "executives": [{
      "name": "Jessica Weitz",
      "title": "Director of Artist Engagement"
    },
    {
      "name": "Justin Kipp",
      "title": "Artist Engagement"
    }
  ]
},
{
  "name": "Alzheimer’s Association",
  "executives": [{
      "name": "Kate Meyer",
      "title": "Vice President, Global Communications"
    },
    {
      "name": "Jen Wirth",
      "title": "Senior Specialist, Media & Entertainment Industry Engagement"
    }
  ]
},
{
  "name": "Amnesty International",
  "executives": [{
      "name": "Matt Vogel",
      "title": "Head of Artist Relations"
    },
    {
      "name": "Aimie Billon",
      "title": "Amnesty USA Arts & Culture Consultant"
    }
  ]
},
{
  "name": "CAPE: Coalition of Asian Pacifics in Entertainment",
  "executives": [{
    "name": "Michelle Sugihara",
    "title": "Executive Director"
  }]
},
{
  "name": "Caring Across Generations",
  "executives": [{
      "name": "Ishita Srivastava",
      "title": "Director of Culture Change"
    },
    {
      "name": "Sarah Vitti",
      "title": "Culture Change Manager"
    }
  ]
},
{
  "name": "Center for Scholars & Storytellers",
  "executives": [{
      "name": "Yalda T. Uhls",
      "title": "Founder and Executive Director"
    },
    {
      "name": "Annie Meyers",
      "title": "Program Director"
    }
]
},
{
  "name": "Color of Change",
  "executives": [{
      "name": "Amity Paye",
      "title": "Culture and Entertainment Advocacy Director"
    },
    {
      "name": "Chasidy Lowe",
      "title": "Culture and Entertainment Advocacy Manager"
    }
  ]
},
{
  "name": "Define American",
  "executives": [{
      "name": "Charlene Jimenez",
      "title": "Director of Entertainment Partnerships and Advocacy"
    },
    {
      "name": "DJ Reed",
      "title": "Senior Manager of Entertainment and Advocacy"
    }
  ]
},
{
  "name": "Everytown for Gun Safety",
  "executives": [
    {
      "name": "Sophie Yan",
      "title": "Director of Cultural and Entertainment Advocacy"
    },
    {
      "name": "Tukio Machini",
      "title": "Manager of Cultural and Entertainment Advocacy"
    }
  ]
},
{
  "name": "Geena Davis Institute on Gender in Media",
  "executives": [{
      "name": "Madeline Di Nonno",
      "title": "President & CEO"
    },
    {
      "name": "Jennifer Suh",
      "title": "Program Manager, Entertainment Outreach"
    }
  ]
},
{
  "name": "GLAAD",
  "executives": [{
      "name": "Jeremy Blacklow",
      "title": "Director of Entertainment Media"
    },
    {
      "name": "Alex Schmider",
      "title": "Associate Director of Transgender Media"
    }
  ]
},
{
  "name": "Harness",
  "executives": [{
    "name": "Miriam Fogelson",
    "title": "Operations Director"
  }]
},
{
  "name": "Hollywood, Health & Society",
  "executives": [{
    "name": "Kate Langrall Folb",
    "title": "Program Director"
  }]
},
{
  "name": "HRC: Human Rights Campaign",
  "executives": [{
      "name": "Jay Brown",
      "title": "Senior Vice President, HRC Foundation"
    },
    {
      "name": "Ty Cobb",
      "title": "Senior Director, Strategic Initiatives & Research, HRC Foundation"
    }
  ]
},
{
  "name": "IllumiNative",
  "executives": [{
      "name": "Crystal Echo Hawk",
      "title": "Executive Director"
    },
    {
      "name": "Leah Salgado",
      "title": "Deputy Director"
    }
  ]
},
{
  "name": "JED Foundation",
  "executives": [{
    "name": "Courtney Knowles",
    "title": " Founder, Love is Louder & Senior Advisor, The Jed Foundation"
  }]
},
{
  "name": "MPAC: Muslim Public Affairs Council",
  "executives": [{
      "name": "Sue Obeidi",
      "title": "Director, Hollywood Bureau"
    },
    {
      "name": "Salam Al-Marayati",
      "title": "President"
    }
  ]
},
{
  "name": "NDWA: National Domestic Workers Alliance",
  "executives": [{
      "name": "Kristina Mevs-Apgar",
      "title": "Culture Change Director"
    },
    {
      "name": "Rachel Birnam",
      "title": "Culture Change Manager"
    }
  ]
},
{
  "name": "NHMC: The National Hispanic Media Coalition ",
  "executives": [{
    "name": "Brenda Castillo",
    "title": "President and CEO"
  }]
},
{
  "name": "NRDC: Natural Resources Defense Council ",
  "executives": [{
      "name": "Cheryl Slean",
      "title": "Co-founder, Rewrite the Future"
    },
    {
      "name": "Katy Jacobs",
      "title": "Manager, Entertainment Partnerships"
    }
  ]
},
{
  "name": "PPFA: Planned Parenthood Federation of America ",
  "executives": [{
      "name": "Caren Spruch",
      "title": "Senior Director, Arts & Entertainment Engagement"
    },
    {
      "name": "Adin Levy",
      "title": "Specialist, Arts & Entertainment Engagement"
    }
  ]
},
{
  "name": "RAINN: Rape, Abuse & Incest National Network ",
  "executives": [{
      "name": "Heather Drevna",
      "title": "Vice President, Communications"
    },
    {
      "name": "Rachel DeLadesmo",
      "title": "Manager, Social Media & Social Impact"
    }
  ]
},
{
  "name": "RespectAbility ",
  "executives": [{
      "name": "Tatiana Lee",
      "title": "Hollywood Inclusion Associate"
    },
    {
      "name": "Lauren Appelbaum",
      "title": "Vice President, Communications"
    }
  ]
},
{
  "name": "Save the Children ",
  "executives": [{
    "name": "Sarah Beisler Nasir",
    "title": "Advisor, Entertainment & Influencer Relations"
  }]
},
{
  "name": "SVA: Student Veterans of America ",
  "executives": [{
      "name": "Ryan Rabac",
      "title": "VP of Communications and Marketing"
    }, 
    {
      "name": "Aimie Billon",
      "title": "Strategic Partnerships Consultant"
    }
  ]
},
{
  "name": "Storyline Partners ",
  "executives": [{
    "name": "Sanaz Alesafar",
    "title": "Program Director"
  }]
},
{
  "name": "Shatterproof ",
  "executives": [{
    "name": "Kirsten Suto Seckler",
    "title": "Chief Marketing & Communications Officer"
  },{
    "name": "Jess Keefe",
    "title": "Digital Strategy Director "
  }]
},
{
  "name": "CCOI: United Nations Creative Community Outreach Initiative *",
  "executives": [{
    "name": "Andi Gitow",
    "title": "Chief of Advocacy, Entertainment Industry Engagement and Civil Society/Department of Global Communications"
  },{
    "name": "Jon Herbertsson",
    "title": "Manager, Creative Community Outreach Initiative/Department of Global Communications "
  }]
}
];

export default advisoryCouncils;